import { useEffect, useState } from "react";

export const usePagination = ({ tableData }) => {
  // const [users, setUsers]= useState([]);
  const [currentPageNumber, setCurrentPageNumber]= useState(1);
  const [appointmentsPerPage, setAppointmentsPerPage]= useState(5);

  const totalPages = [];

  for (let i = 1; i <= Math.ceil(tableData.length / appointmentsPerPage); i++) {
    totalPages.push(i);
  }

  const indexOfLastUser = currentPageNumber * appointmentsPerPage;
  const indexOffirstUser = indexOfLastUser - appointmentsPerPage;
  const paginatedResults = tableData.slice(indexOffirstUser, indexOfLastUser);

  const handlePrev = ()=> {
      if(currentPageNumber === 1) return
      setCurrentPageNumber(currentPageNumber - 1)
  };
  const handleNext = ()=> {
      if(currentPageNumber === totalPages.length) return
      setCurrentPageNumber(currentPageNumber + 1)
  };

   return {
    currentPageNumber,
    setCurrentPageNumber,
    appointmentsPerPage,
    setAppointmentsPerPage,
    totalPages,
    paginatedResults,
    handleNext,
    handlePrev,
   };
}
