import { Link } from "react-router-dom";

function TableBody({ data }) {

  return (
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
          <td className="px-6 py-4 whitespace-nowrap">{item.treatment}</td>
          
          <td className="px-6 py-4 whitespace-nowrap">{item.status}</td>
          <td
            className="px-4 py-4 whitespace-nowrap ml-4"
            id={item._id}
          >
            {item.paid ? (
              <div>
                <p className="badge badge-secondary">Paid</p>
                <br></br>
                <span>  TransactionID: {item.transactionId}</span>
              </div>
              
            ) : (
              <>
                <div className="badge badge-error">Unpaid</div>
                <Link to={`payment/${item._id}`}
                className="btn btn-link">Pay Now</Link>
              </>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default TableBody;
