import './App.css';
import Navbar from './Pages/Shared/Navbar';
import { Routes, Route, Link } from "react-router-dom";
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Login from './Pages/Login/Login';
import Appointment from './Pages/Appointment/Appointment';
import SignUp from './Pages/Login/SignUp';
import RequireAuth from './Pages/Login/RequireAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './Pages/Dashboard/Dashboard';
import MyAppointments from './Pages/Dashboard/MyAppointments';
import MyReview from './Pages/Dashboard/MyReview';
import MyHistory from './Pages/Dashboard/MyHistory';
import Users from './Pages/Dashboard/Users';
import ManageServices from './Pages/Dashboard/ManageServices';
import AddService from './Pages/Dashboard/AddService';
import RequireAdmin from './Pages/Login/RequireAdmin';
import Contact from './Pages/Home/Contact';
import Review from './Pages/Home/Review';
import Payment from './Pages/Dashboard/Payment';
import NewReview from './Pages/Review/NewReview';

function App() {
  return (
    <div className='max-w-7xl mx-auto px-12'>
      <Navbar></Navbar>
      <Routes>

        <Route path="review" element={
          <RequireAuth>
            {/* <MyReview /> */}
            <NewReview />
          </RequireAuth>
        } />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="appointment" element={
          <RequireAuth>
            <Appointment />
          </RequireAuth>}
        />
        {/* <Route path="payment/:id" element={<Payment></Payment>}></Route> */}

        <Route path="/" element={<Home />} />
        <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} >
          <Route path="appointment" index element={
            <RequireAuth>
              <Appointment />
            </RequireAuth>}>
          </Route>
          {/* <Route path="review" element={<MyReview></MyReview>}></Route> */}
          <Route path="review" element={<NewReview></NewReview>}></Route>
          <Route path="history" element={<MyHistory></MyHistory>}></Route>
          <Route path="payment/:id" element={<Payment></Payment>}></Route>
          <Route path="users" element={<RequireAdmin><Users></Users></RequireAdmin>}></Route>
          <Route path="addService" element={<RequireAdmin><AddService></AddService></RequireAdmin>}></Route>
          <Route path="manageService" element={<RequireAdmin><ManageServices></ManageServices></RequireAdmin>}></Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;









// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
