import React from 'react';
import { Link } from 'react-router-dom';
import footer from '../../assets/images/footer.png';

const Footer = () => {
    return (
        <footer style={{
            background: `url(${footer})`,
            backgroundSize: 'cover'
        }} className="p-10">
            <div className='footer'>
                <div>
                    <span className="footer-title">Services</span>
                    <Link to="/appointment"> <a className="link link-hover">Home Interior Design</a></Link>
                    <Link to="/appointment"> <a className="link link-hover">Home Cleaning</a></Link>
                    <Link to="/appointment"> <a className="link link-hover">Bathroom and Toilet Cleaning</a></Link>
                    <Link to="/appointment"> <a className="link link-hover">Electronics Repair</a></Link>
                </div>
                <div>
                    <span className="footer-title">Company</span>
                    <Link to="/about">  <a className="link link-hover">About us</a></Link>
                    <Link to="/contact"> <a className="link link-hover">Contact</a></Link>
                    
                </div>
                <div>
                    <span className="footer-title">Legal</span>
                    <Link to="/about">  <a className="link link-hover">Terms & Conditions</a></Link>
                    <Link to="/about">  <a className="link link-hover">Privacy Policy</a></Link>
                    <Link to="/about">   <a className="link link-hover">Cookie Policy</a></Link>
                </div>
            </div>
            <div className='my-10 text-center'>
                <p>Copyright © 2022 Online Home Refinement System</p>
            </div>
        </footer>
    );
};

export default Footer;