import React from 'react';
import clean from '../../assets/images/clean.jpg';
import PrimaryButton from '../Shared/PrimaryButton';

const LearnMore = () => {
    return (
        <div className="hero min-h-screen">
            <div className="hero-content flex-col lg:flex-row">
                <img style={{ border: '5px solid pink' }} src={clean} width={458} className="max-w-sm rounded-lg shadow-2xl" />
                <div>
                    <h1 className="text-5xl font-bold">Exceptional Services at Your Doorstep</h1>
                    <p className="py-6 large-letter">Elevate Your Home with Exceptional Services at Your Doorstep. Discover the ultimate in convenience as we bring our unmatched home refinery services directly to your front door. Our dedicated team of experts is ready to transform your living space, and we're just a click or call away. Explore our comprehensive range of exceptional offerings and take the first step towards enhancing your home today.</p>
                    <PrimaryButton>Learn More</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default LearnMore;