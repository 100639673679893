// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID
// };
const firebaseConfig = {
    apiKey: "AIzaSyA-vZHnCsG-ahQBuKA0IxRIVT7Vo3RUe5c",
    authDomain: "home-refinery.firebaseapp.com",
    projectId: "home-refinery",
    storageBucket: "home-refinery.appspot.com",
    messagingSenderId: "715786648660",
    appId: "1:715786648660:web:4529958ee64fb4219241c1",
    measurementId: "G-JR8Z6289CT"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;