import React, { useState, useMemo, useEffect } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import Pagination from "./Pagination";
import { baseUrl } from "../Shared/BaseUrl";
import { usePagination } from "../../hooks/usePagination";

function Table() {
  const [user] = useAuthState(auth);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/booking?patient=${user?.email}`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.length > 0)
        if (data.length > 0){
          const dashboardData = data?.map(data => data)
        console.log(dashboardData)
        setTableData(dashboardData)
        }
        
      });
  }, []);

  const {
    currentPageNumber,
    setCurrentPageNumber,
    appointmentsPerPage,
    setAppointmentsPerPage,
    totalPages,
    paginatedResults,
    handleNext,
    handlePrev,
  } = usePagination({tableData});

  console.log(totalPages, currentPageNumber)

  const sortedData = useMemo(() => {
    if (sortBy && sortOrder) {
      const sorted = [...tableData].sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });

      return sorted;
    }
    return tableData;
  }, [tableData, sortBy, sortOrder]);

  const handleSort = (columnName) => {
    if (columnName === sortBy) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  return (
    <div>
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <TableHeader
          setSortBy={handleSort}
          setSortOrder={setSortOrder}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        <TableBody data={paginatedResults} />
      </table>
      <Pagination
        totalPages={totalPages}
        currentPageNumber={currentPageNumber}
        postsPerPage={appointmentsPerPage}
        setAppointmentsPerPage={setAppointmentsPerPage}
        handleCurrentPageNumber={setCurrentPageNumber}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    </div>
  );
}

export default Table;
