import React from 'react';
import './NewReview.css'; // Import your CSS styles

const NewReview = () => {
  const reviews = [
    {
      id: 1,
      name: 'MD.Radwan hossain',
      review: 'I was amazed by the exceptional service provided by HomeRefine. Their attention to detail and creative approach made my home renovation project a breeze.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Shafin Ahmed',
      review: 'HomeRefine exceeded my expectations in every way possible. From the initial consultation to the final result, everything was perfect.',
      rating: 4.5,
    },
    {
      id: 2,
      name: 'Taskin Kabir Evan',
      review: 'HomeRefine exceeded my expectations in every way possible. From the initial consultation to the final result, everything was perfect.',
      rating: 4.5,
    },
    {
      id: 2,
      name: 'Saima Tanni',
      review: 'HomeRefine exceeded my expectations in every way possible. From the initial consultation to the final result, everything was perfect.',
      rating: 4.5,
    }
    // Add more reviews here
  ];

  return (
    <div className="review-page-container">
      <h1>Customer Reviews</h1>
      <div className="reviews">
        {reviews.map((review) => (
          <div className="review" key={review.id}>
            <h2>{review.name}</h2>
            <div className="rating">
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className={index < review.rating ? 'star filled' : 'star'}>★</span>
              ))}
            </div>
            <p>{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewReview;