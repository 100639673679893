import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Loading from '../Shared/Loading';
import CheckoutForm from './CheckoutForm';
import { baseUrl } from '../Shared/BaseUrl';

const stripePromise = loadStripe('pk_test_51NRUr1JD1kDnatz0jxnhiKHi4H4FQkI6piC3EkRMdUtwHWMzN9vjvVYyIIDu2mFrjweAILhDmrt0AhPENIT0Rtwi007i4plSO5');

const Payment = () => {
    const { id } = useParams();
    const url = `${baseUrl}/booking/${id}`;
    // const appointment = ""

    const { data: appointment, isLoading } = useQuery(['booking', id], () => fetch(url, {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
    }).then(res => res.json()));

    if (isLoading) {
        return <Loading></Loading>
    }
    console.log(appointment)

    return (
        <>
            {!appointment.paid ? (
                <div>
                    <div class="card w-50 max-w-md bg-base-100 shadow-xl mb-12">
                        <div class="card-body">
                            <p className="text-success font-bold">Hello, {appointment.patientName}</p>
                            <h2 class="card-title">Please Pay for <span className='text-indigo-600'>{appointment.treatment}</span></h2>
                            <p>Your Appointment: <span className='text-indigo-600'>{appointment.date}</span> at {appointment.slot}</p>
                            <p>Payment: <strong>${appointment.price}</strong></p>
                        </div>
                    </div>
                    <div class="card flex-shrink-0 w-50 max-w-md shadow-2xl bg-base-100">
                        <div class="card-body">
                            <Elements stripe={stripePromise}>
                                <CheckoutForm appointment={appointment} />
                            </Elements>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Payment;