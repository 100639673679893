import Form from "./Form";
import { React, createContext, useState } from "react";
import ReactSwitch from "react-switch";
import "./About.css";

export const ThemeContext = createContext(null);

// const About = () => {
//     const [theme, setTheme] = useState("dark");

//     const toggleTheme = () => {
//         setTheme((curr) => (curr === "light" ? "dark" : "light"));
//     };
//     return (
//         <ThemeContext.Provider value={{ theme, toggleTheme }}>
//             <div className="App" id={theme}>
//                 <Form />
//                 <div className="switch">
//                     <label> {theme === "light" ? "Light Mode" : "Dark Mode"}</label>
//                     <ReactSwitch onChange={toggleTheme} checked={theme === "dark"} />
//                 </div>
//             </div>
//         </ThemeContext.Provider>
//     );
// }

// export default About;

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <p>Welcome to HomeRefine Services! We are a dedicated team of professionals passionate about refining your home to perfection.</p>
      <p>Our mission is to provide top-notch home refinement services that cater to your unique needs and preferences. Whether it's interior design, renovation, or landscaping, we've got you covered.</p>
      <p>With years of experience in the industry, we pride ourselves on delivering exceptional results that exceed our clients' expectations. Our attention to detail, creativity, and commitment to quality set us apart.</p>
      <p>We believe that your home should be a reflection of your personality and style. That's why we work closely with you to understand your vision and bring it to life.</p>
      <p>At HomeRefine Services, customer satisfaction is our priority. We ensure clear communication throughout the project, keeping you informed every step of the way.</p>
      <p>Thank you for considering us for your home refinement needs. We look forward to transforming your house into the home of your dreams.</p>
      <p>Contact us today to get started!</p>

      <h1>Terms & Conditions</h1>
      <p>Welcome to HomeRefine Services! We are a dedicated team of professionals passionate about refining your home to perfection.</p>
      <p>Our mission is to provide top-notch home refinement services that cater to your unique needs and preferences. Whether it's interior design, renovation, or landscaping, we've got you covered.</p>
      <p>With years of experience in the industry, we pride ourselves on delivering exceptional results that exceed our clients' expectations. Our attention to detail, creativity, and commitment to quality set us apart.</p>
      <p>We believe that your home should be a reflection of your personality and style. That's why we work closely with you to understand your vision and bring it to life.</p>
      <p>At HomeRefine Services, customer satisfaction is our priority. We ensure clear communication throughout the project, keeping you informed every step of the way.</p>
      <p>Thank you for considering us for your home refinement needs. We look forward to transforming your house into the home of your dreams.</p>
      <p>Contact us today to get started!</p>

      <h1>Privacy Policy</h1>
      <p>Welcome to HomeRefine Services! We are a dedicated team of professionals passionate about refining your home to perfection.</p>
      <p>Our mission is to provide top-notch home refinement services that cater to your unique needs and preferences. Whether it's interior design, renovation, or landscaping, we've got you covered.</p>
      <p>With years of experience in the industry, we pride ourselves on delivering exceptional results that exceed our clients' expectations. Our attention to detail, creativity, and commitment to quality set us apart.</p>
      <p>We believe that your home should be a reflection of your personality and style. That's why we work closely with you to understand your vision and bring it to life.</p>
      <p>At HomeRefine Services, customer satisfaction is our priority. We ensure clear communication throughout the project, keeping you informed every step of the way.</p>
      <p>Thank you for considering us for your home refinement needs. We look forward to transforming your house into the home of your dreams.</p>
      <p>Contact us today to get started!</p>

      <h1>Cookies Policy</h1>
      <p>Welcome to HomeRefine Services! We are a dedicated team of professionals passionate about refining your home to perfection.</p>
      <p>Our mission is to provide top-notch home refinement services that cater to your unique needs and preferences. Whether it's interior design, renovation, or landscaping, we've got you covered.</p>
      <p>With years of experience in the industry, we pride ourselves on delivering exceptional results that exceed our clients' expectations. Our attention to detail, creativity, and commitment to quality set us apart.</p>
      <p>We believe that your home should be a reflection of your personality and style. That's why we work closely with you to understand your vision and bring it to life.</p>
      <p>At HomeRefine Services, customer satisfaction is our priority. We ensure clear communication throughout the project, keeping you informed every step of the way.</p>
      <p>Thank you for considering us for your home refinement needs. We look forward to transforming your house into the home of your dreams.</p>
      <p>Contact us today to get started!</p>
    </div>
  );
}

export default AboutUs;