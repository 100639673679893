import kitchen from '../../assets/images/kitchen.png';
import PrimaryButton from "../Shared/PrimaryButton";
import React, { useState } from 'react';
import { baseUrl } from "../Shared/BaseUrl";
import { toast } from "react-toastify";

const Contact = () => {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const handleBooking = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const subject = event.target.subject.value;
        const message = event.target.message.value;

        const booking = {
          message: message,
          email: email,
          subject: subject
        };
    
        fetch(`${baseUrl}/contact`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(booking),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              toast(`Conact sent successfully`);
            } else {
              toast.error(
                `Error Happend`
              );
            }
            setEmail("");
            setSubject("");
            setMessage("");
          });
      };


    return (
        <div style={{
            border: '5px solid pink',
            background: `url(${kitchen})`, backgroundSize: '120%'
        }} className='bg-primary px-10 py-14 '>
            <div className='text-center pb-14 text-white'>
                <p className='text-xl font-bold text-primary'>
                    Contact Us
                </p>
                <h1 className='text-3xl'>Stay connected with us</h1>
            </div>
             <form
            onSubmit={handleBooking}
          >
            <div className='grid grid-cols-1 justify-items-center gap-5'>
           
                <input
                    name="email"
                    type='text'
                    value={email}
                    placeholder='Email Address'
                    className='input w-full max-w-md'
                    onChange={(e) => setEmail(e.target.value)} required
                />
                <input
                    name="subject"
                    type='text'
                    value={subject}
                    placeholder='Subject'
                    className='input w-full max-w-md'
                    onChange={(e) => setSubject(e.target.value)} required
                />
                <textarea
                    name="message"
                    value={message}
                    className='textarea w-full max-w-md'
                    placeholder='Your message'
                    rows={6}
                    onChange={(e) => setMessage(e.target.value)} required
                ></textarea>
                {/* <PrimaryButton>Submit</PrimaryButton> */}
                <button className="btn btn-primary text-white font-bold bg-gradient-to-r from-[#314755] to-[#26a0da]">Submit</button>

            
            
            </div>
          </form>
            
        </div>
    );
};

export default Contact;