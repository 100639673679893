function TableHeader({ setSortBy, setSortOrder, sortBy, sortOrder }) {
  // Logic for sorting the table data based on the selected column
  const handleSort = (columnName) => {
    if (columnName === sortBy) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const getSortIndicator = (columnName) => {
    if (columnName === sortBy) {
      return sortOrder === "asc" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <thead className="bg-purple-500">
      <tr>
        <th
          onClick={() => handleSort("date")}
          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
        >
          Date {getSortIndicator("date")}
        </th>
        <th
          onClick={() => handleSort("serviceType")}
          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
        >
          Service {getSortIndicator("serviceType")}
        </th>
       
        <th
          onClick={() => handleSort("serviceStatus")}
          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
        >
          Status {getSortIndicator("serviceStatus")}
        </th>
        <th
          onClick={() => handleSort("paymentStatus")}
          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
        >
          Payment {getSortIndicator("paymentStatus")}
        </th>
      </tr>
    </thead>
  );
}

export default TableHeader;
